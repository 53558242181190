import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { API_CONNECTION } from "../config/Const";
import { useRecoilState } from "recoil";
import { loginInfoAtom, showLoaderAtom } from "../store/atom";
import { api, isset } from "../util/util";
import { Alert } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
        Hey-Jee!&nbsp;
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useRecoilState(loginInfoAtom);

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const [errorList, setErrorList] = React.useState({});

  const resetTimer = React.useRef(null);

  //初期処理
  React.useEffect(() => {
    api({ url: `${API_CONNECTION.URL}api/login` })
      .then(() => {
        // setReloadTimer();
      })
      .catch(() => {});
  }, []);

  React.useEffect(() => {
    // setReloadTimer();
  }, [formData]);

  const setReloadTimer = () => {
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }

    //１時間半操作されなかったらリロード
    resetTimer.current = setTimeout(() => {
      window.location.href = "";
    }, 5400000);
  };

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});

    const postData = {
      email: formData.email,
      password: formData.password,
    };

    setShowLoader(true);

    api({ url: `${API_CONNECTION.URL}api/login`, type: "post", data: postData })
      .then((res) => {
        console.dir(res);
        if (isset(res.data.ret) && res.data.ret === true) {
          setLoginInfo({});
          navigate("/creator");
        } else {
          if (isset(res.data.error) && res.data.error) {
            setShowLoader(false);
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              type="email"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleForm}
            />
            <Alert
              variant="outlined"
              severity="error"
              className="error-alert"
              style={{ display: isset(errorList.email) ? "" : "none" }}
            >
              {errorList.email}
            </Alert>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              value={formData.password}
              onChange={handleForm}
            />
            <Alert
              variant="outlined"
              severity="error"
              className="error-alert"
              style={{ display: isset(errorList.password) ? "" : "none" }}
            >
              {errorList.password}
            </Alert>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Alert
              variant="outlined"
              severity="error"
              className="error-alert"
              style={{ display: isset(errorList.login) ? "" : "none" }}
            >
              {errorList.login}
            </Alert>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
