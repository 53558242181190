import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom";
import { API_CONNECTION, SYSTEM_CONST } from "../config/Const";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Checkbox,
  Chip,
  Container,
  Dialog,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import {
  AccountCircle,
  Add,
  Search,
  Settings,
  Link as LinkIcon,
  Business,
  Circle,
  TaskAlt,
  CircleOutlined,
} from "@mui/icons-material";
import CreatorEditor from "./CreatorEditor";
import UrlDialog from "../component/UrlDialog";
import PromotionEditor from "./PromotionEditor";

const columns = [
  { id: "promotion_name", label: "案件", align: "left", sortable: true },
  { id: "term_from", label: "PR期間", align: "left", sortable: true },
  {
    id: "entry_term_from",
    label: "エントリー期間",
    align: "left",
    sortable: true,
  },
  {
    id: "productions",
    label: "参加プロダクション",
    align: "left",
    sortable: false,
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Promotion() {
  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [currentMenu, setCurrentMenu] = useRecoilState(currentMenuAtom);

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [promotionList, setPromotionList] = useState([]);
  const [promotionCount, setPromotionCount] = useState(0);
  const [promotionListPage, setPromotionListPage] = useState(0);
  const [promotionListRows, setPromotionListRows] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  const [searchInput, setSearchInput] = useState("");
  const [searchStatus, setSearchStatus] = useState([]);

  const [formData, setFormData] = useState({});
  const [errorList, setErrorList] = useState({});

  const [editorOpen, setEditorOpen] = useState(false);
  const [urlOpen, setUrlOpen] = useState(false);

  const [editId, setEditId] = useState(0);

  const [urlInfos, setUrlInfos] = useState([]);

  const [listData, setListData] = useState({});

  const useEffectInit = useRef(false);

  const listSetting = (listdata) => {
    setCurrentMenu("promotion");
    setPromotionList(listdata.promotions);
    setPromotionCount(listdata.allCount);
    setPromotionListPage(listdata.page);
    setPromotionListRows(listdata.rowsPerPage);
    setOrder(listdata.order);
    setOrderBy(listdata.orderBy);
  };

  //初期処理
  useEffect(() => {
    setCurrentMenu("promotion");

    if (isset(loadData.searchSet)) {
      if (
        isset(loadData.searchSet.searchInput) &&
        loadData.searchSet.searchInput
      ) {
        setSearchInput(loadData.searchSet.searchInput);
      }
      if (
        isset(loadData.searchSet.searchStatus) &&
        loadData.searchSet.searchStatus
      ) {
        console.dir(loadData.searchSet.searchStatus);
        setSearchStatus(loadData.searchSet.searchStatus);
      }
    }

    listSetting(loadData);
    setListData(loadData.list);

    // 初期フラグを少し遅れて設定
    setTimeout(() => {
      useEffectInit.current = true;
    }, 100);
  }, []);

  //検索
  const getSearchData = () => {
    if (useEffectInit.current === false) {
      return;
    }

    const sInputQuery = encodeURI(searchInput);
    const uniqueStatus = [...new Set(searchStatus)]; // 重複を省く
    const searchStatusQuery = uniqueStatus.join("-");

    setShowLoader(true);
    api({
      url: `${API_CONNECTION.URL}api/promotions?page=0&searchInput=${sInputQuery}&searchStatus=${searchStatusQuery}`,
    })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  //検索窓監視
  useEffect(() => {
    if (useEffectInit.current === false) {
      return;
    }

    const timer = setTimeout(getSearchData, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const handleForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (useEffectInit.current === false) {
      return;
    }

    getSearchData();
  }, [searchStatus]);

  const handleChangePage = (event, newPage) => {
    api({ url: `${API_CONNECTION.URL}api/promotions?page=${newPage}` })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((err) => {});
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsVal = parseInt(event.target.value, 10);
    setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/promotions?page=0&rows=${rowsVal}` })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const handleChangeSort = (columnName) => {
    const selected = columnName === orderBy;
    api({
      url: `${API_CONNECTION.URL}api/promotions?page=0&order_by=${columnName}&order=${order}&selected=${selected}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((res) => {});
  };

  const handleSearchStatusChange = (event, status) => {
    const newStatus = event.target.checked
      ? [...searchStatus, status]
      : searchStatus.filter((item) => item !== status);
    setSearchStatus(newStatus);
  };

  const handleEditorOpen = (creatorId) => {
    setEditId(creatorId);
    setEditorOpen(true);
  };

  const handleEditorClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
        <h2>Promotion</h2>
        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{ position: "absolute", top: 80, right: 15 }}
          onClick={() => {
            handleEditorOpen(0);
          }}
        >
          <Add />
        </Fab>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel htmlFor="input-with-icon-adornment">
                Search Text
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                value={searchInput}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel shrink>Status</InputLabel>
            {isset(listData.arrStatus) &&
              Object.keys(listData.arrStatus).map((status) => (
                <FormControlLabel
                  key={status}
                  label={listData.arrStatus[status]}
                  control={
                    <Checkbox
                      size="small"
                      icon={<CircleOutlined />}
                      checkedIcon={<TaskAlt />}
                      name={"search_status_" + status}
                      value={status}
                      onChange={(e) => handleSearchStatusChange(e, status)}
                      checked={searchStatus.includes(status) ? true : false}
                    />
                  }
                />
              ))}
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: window.innerHeight }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        // <TableCell
                        //   key={column.id}
                        //   align={column.align}
                        //   sx={{ fontWeight: "bold" }}
                        // >
                        //   {column.label}
                        // </TableCell>

                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ fontWeight: "bold" }}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          {column.sortable === true ? (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => handleChangeSort(column.id)}
                            >
                              {column.label}
                              {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <> {column.label}</>
                          )}
                        </TableCell>
                      ))}
                      <TableCell key="edit" align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promotionList.map((promotion) => {
                      return (
                        <TableRow hover role="checkbox" key={promotion.id}>
                          {columns.map((column) => {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "promotion_name" ? (
                                  <>
                                    {promotion["icon_img_url"] !== null && (
                                      <div>
                                        <img
                                          src={promotion["icon_img_url"]}
                                          style={{
                                            maxWidth: "200px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {promotion["status"] === "開催前" ? (
                                      <Chip
                                        label={promotion["status"]}
                                        color="secondary"
                                        variant="outlined"
                                      />
                                    ) : promotion["status"] === "開催中" ? (
                                      <Chip
                                        label={promotion["status"]}
                                        color="secondary"
                                      />
                                    ) : promotion["status"] === "開催終了" ? (
                                      <Chip label={promotion["status"]} />
                                    ) : (
                                      <Chip
                                        label={promotion["status"]}
                                        variant="outlined"
                                      />
                                    )}
                                    <span style={{ marginLeft: "10px" }}>
                                      『{promotion["promotion_name"]}』
                                    </span>
                                  </>
                                ) : column.id === "term_from" ? (
                                  <>
                                    <small>{promotion["term"]}</small>
                                  </>
                                ) : column.id === "entry_term_from" ? (
                                  <>
                                    <small>{promotion["entry_term"]}</small>
                                  </>
                                ) : column.id === "productions" ? (
                                  <>
                                    {promotion[
                                      "promotion_allow_production"
                                    ].map((production) => (
                                      <Chip
                                        key={production.id}
                                        icon={<Business />}
                                        label={production.production_name}
                                        sx={{ mr: 0.5, mb: 0.5 }}
                                      />
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {column.format &&
                                    typeof promotion[column.id] === "number"
                                      ? column.format(promotion[column.id])
                                      : promotion[column.id]}
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell key="edit" align="left">
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              size="small"
                              onClick={(e) => {
                                handleEditorOpen(promotion.id);
                              }}
                              sx={{
                                textAlign: "center",
                                padding: "0",
                                mr: "10px",
                              }}
                            >
                              <Settings />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={loadData.arrPageRowNum}
                component="div"
                count={promotionCount}
                rowsPerPage={promotionListRows}
                page={promotionListPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen
        open={editorOpen}
        onClose={handleEditorClose}
        TransitionComponent={Transition}
      >
        <PromotionEditor
          closeFunc={handleEditorClose}
          promotionId={editId}
          key={editId} //子を初期化させるために必要
          handleChangePage={handleChangePage}
          promotionListPage={promotionListPage}
        />
      </Dialog>
    </>
  );
}

/*
function Profile() {
  return <h2>記事2</h2>;
}

*/

export default Promotion;
