import { useState, useEffect, Fragment } from "react";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Close,
  Delete,
  Done,
  TaskAlt,
  CircleOutlined,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { showLoaderAtom } from "../store/atom";
import { api, isset } from "../util/util";
import { API_CONNECTION } from "../config/Const";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import PromotionEntryList from "./PromotionEntryList";
import ConfirmDialog from "../component/ConfirmDialog";

function PromotionEditor({
  closeFunc,
  promotionId = 0,
  handleChangePage,
  promotionListPage,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  // 新規登録か編集かを判定
  const isEdit = promotionId !== 0;

  // 取得したリストデータ（例: 参加プロダクション一覧など）
  const [listData, setListData] = useState({});

  const [errorList, setErrorList] = useState({});
  const [showDelConfirm, setShowDelConfirm] = useState(0);

  // フォーム入力データ
  const [formData, setFormData] = useState({
    promotion_name: "",
    // PR期間 From
    term_from_ymd: null,
    term_from_h: "00",
    term_from_m: "00",
    // PR期間 To
    term_to_ymd: null,
    term_to_h: "00",
    term_to_m: "00",
    // エントリー期間 From
    entry_term_from_ymd: null,
    entry_term_from_h: "00",
    entry_term_from_m: "00",
    // エントリー期間 To
    entry_term_to_ymd: null,
    entry_term_to_h: "00",
    entry_term_to_m: "00",
    // 有効フラグ
    active_flg: 1,
    // 参加プロダクション (複数選択)
    allow_production: [],
    guidance_urls: {},
    // アイコン / PRバナー
    icon: null,
    pr_banner: null,
  });

  // 初期読み込み（編集時のみAPIで既存データをGET）
  useEffect(() => {
    setShowLoader(true);
    api({ url: `${API_CONNECTION.URL}api/promotions/${promotionId}` })
      .then((res) => {
        // 取得したデータからStateを更新
        const { promotion, list } = res.data;

        if (isEdit) {
          setFormData({
            ...promotion,
            term_from_ymd: dayjs(promotion.term_from_ymd),
            term_to_ymd: dayjs(promotion.term_to_ymd),
            entry_term_from_ymd: isset(promotion.entry_term_from_ymd)
              ? dayjs(promotion.entry_term_from_ymd)
              : null,
            entry_term_to_ymd: isset(promotion.entry_term_to_ymd)
              ? dayjs(promotion.entry_term_to_ymd)
              : null,
            // 以下、APIから返ってくるデータに応じて初期化
            // active_flg, allow_production など
            // icon, pr_banner はサーバー上にあるURLの場合もあるが、
            // ここでは新規アップロード分を null として扱う想定
          });
        }
        setListData(list);
        setShowLoader(false);
      })
      .catch(() => setShowLoader(false));
  }, [promotionId]);

  // フォーム要素変更ハンドラー
  const handleForm = (event) => {
    const { name, value, checked, type } = event.target;

    console.log(value);

    // active_flg (Switch)
    if (name === "active_flg") {
      setFormData({ ...formData, active_flg: checked ? 1 : 0 });
      return;
    }

    // 参加プロダクション (Checkbox)
    if (name.startsWith("allow_production_")) {
      const productionId = parseInt(value, 10);
      const updatedList = checked
        ? [...formData.allow_production, productionId]
        : formData.allow_production.filter((id) => id !== productionId);

      setFormData({ ...formData, allow_production: updatedList });
      return;
    }

    // プロダクションURL
    if (name.startsWith("guidance_urls_")) {
      const productionId = parseInt(name.replace("guidance_urls_", ""), 10);
      setFormData((prev) => ({
        ...prev,
        guidance_urls: {
          ...prev.guidance_urls,
          [productionId]: value,
        },
      }));
      return;
    }

    // 数値型のSelectを使っているケース（例: 時、分）なら parseInt
    if (
      [
        "term_from_h",
        "term_from_m",
        "term_to_h",
        "term_to_m",
        "entry_term_from_h",
        "entry_term_from_m",
        "entry_term_to_h",
        "entry_term_to_m",
      ].includes(name)
    ) {
      setFormData({
        ...formData,
        [name]: parseInt(value, 10).toString().padStart(2, "0"),
      });
      return;
    }

    // それ以外の単純な入力
    setFormData({ ...formData, [name]: value });
  };

  // DatePickerの変更ハンドラー
  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  // アイコン用 Dropzone
  const { getRootProps: getIconRootProps, getInputProps: getIconInputProps } =
    useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles) =>
        setFormData({ ...formData, icon: acceptedFiles[0] }),
    });

  // PRバナー用 Dropzone
  const {
    getRootProps: getBannerRootProps,
    getInputProps: getBannerInputProps,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) =>
      setFormData({ ...formData, pr_banner: acceptedFiles[0] }),
  });

  // フォーム送信（登録 or 更新）
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowLoader(true);

    // 画像を含むので FormData で送信
    const formDataToSend = new FormData();

    // FormDataに全ての項目をセット
    // icon, pr_banner は Fileオブジェクトの場合だけappend
    Object.keys(formData).forEach((key) => {
      const value = formData[key];

      if (value !== null) {
        // 'allow_production'の場合、配列の各要素を個別にappend
        if (key === "allow_production" && Array.isArray(value)) {
          value.forEach((item) => formDataToSend.append(key + "[]", item)); // 配列の各要素を個別に送信
        }
        // 'guidance_urls'がオブジェクトの場合、オブジェクトの各プロパティを個別にappend
        else if (key === "guidance_urls" && typeof value === "object") {
          Object.entries(value).forEach(([prodId, url]) => {
            console.dir(url);
            if (url && url != "null") {
              formDataToSend.append(`guidance_urls[${prodId}]`, url);
            }
          });
        }
        // 日付フィールドに対する処理（dayjsでフォーマット）
        else if (key.includes("_ymd") && value) {
          formDataToSend.append(key, dayjs(value).format("YYYY-MM-DD"));
        }
        // ファイルの場合
        else if (value instanceof File) {
          formDataToSend.append(key, value);
        }
        // その他の単純な値
        else {
          formDataToSend.append(key, value);
        }
      }
    });

    Object.entries(formData.guidance_urls).forEach(([prodId, url]) => {
      formDataToSend.append(`guidance_urls[${prodId}]`, url);
    });

    const apiUrl = isEdit
      ? `${API_CONNECTION.URL}api/promotions/${promotionId}`
      : `${API_CONNECTION.URL}api/promotions`;
    const apiMethod = "post";

    api({
      url: apiUrl,
      type: apiMethod,
      data: formDataToSend,
      addHeader: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          enqueueSnackbar("Promotion saved successfully!", {
            variant: "success",
          });

          // 一覧ページへの遷移など
          if (handleChangePage) {
            handleChangePage(null, promotionListPage || 0);
          }
          closeFunc();
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => setShowLoader(false));
  };

  // 削除ボタン（編集時のみ）
  const handleDelete = (event) => {
    event.preventDefault();
    setShowLoader(true);

    api({
      url: `${API_CONNECTION.URL}api/promotions/${promotionId}`,
      type: "delete",
    })
      .then(() => {
        setShowLoader(false);
        enqueueSnackbar("Promotion deleted successfully!", {
          variant: "success",
        });

        if (handleChangePage) {
          handleChangePage(null, promotionListPage || 0);
        }
        closeFunc();
      })
      .catch(() => setShowLoader(false));
  };

  const hours = [...Array(24).keys()].map((h) => h.toString().padStart(2, "0"));
  const minutes = [0, 15, 30, 45].map((m) => m.toString().padStart(2, "0"));

  return (
    <Fragment>
      {/* ヘッダー */}
      <AppBar sx={{ position: "relative" }} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeFunc}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
            {isEdit
              ? `Edit Promotion ( ${formData.promotion_name} )`
              : "New Promotion"}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Done />}
            onClick={handleSubmit}
            sx={{ ml: 1 }}
          >
            SAVE
          </Button>
          {isEdit && (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
              onClick={() => {
                setShowDelConfirm(1);
              }}
              sx={{ ml: 2 }}
            >
              Delete
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* コンテンツ */}
      <Container maxWidth="100%" component="main">
        <CssBaseline />
        <Box component="form" noValidate sx={{ mt: 3, mb: 15 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={isEdit ? 4 : 6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel shrink required>
                    状態
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.active_flg}
                        name="active_flg"
                        onChange={handleForm}
                      />
                    }
                    label="有効"
                  />
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.active_flg) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.active_flg}
                  </Alert>
                </Grid>
                {/* 案件名 */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextField
                    required
                    fullWidth
                    label="案件名"
                    name="promotion_name"
                    value={formData.promotion_name}
                    onChange={handleForm}
                  />
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.promotion_name) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.promotion_name}
                  </Alert>
                </Grid>

                {/* 参加プロダクション */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <InputLabel shrink>参加プロダクション</InputLabel>
                  {isset(listData.arrProduction) &&
                    listData.arrProduction &&
                    Object.keys(listData.arrProduction).map((productionId) => (
                      <Grid container spacing={0} sx={{ mt: 1 }}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            label={listData.arrProduction[productionId]}
                            control={
                              <Checkbox
                                icon={<CircleOutlined />}
                                checkedIcon={<TaskAlt />}
                                name={"allow_production_" + productionId}
                                value={productionId}
                                checked={formData.allow_production.includes(
                                  parseInt(productionId, 10)
                                )}
                                onChange={handleForm}
                              />
                            }
                            sx={{ mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            label="遷移先URL"
                            name={"guidance_urls_" + productionId}
                            value={formData.guidance_urls[productionId] || ""}
                            onChange={handleForm}
                            size="small"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    ))}

                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.allow_production) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.allow_production}
                  </Alert>
                </Grid>

                {/* PR期間 */}
                <Grid item xs={12} sx={{ mt: 4 }}>
                  <InputLabel shrink required>
                    PR期間
                  </InputLabel>
                </Grid>
                {/* 開始 */}
                <Grid item xs={6}>
                  <DatePicker
                    label="開始日"
                    value={formData.term_from_ymd}
                    onChange={(date) => handleDateChange("term_from_ymd", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    inputFormat="YYYY/MM/DD"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="term_from_h"
                    value={formData.term_from_h}
                    onChange={handleForm}
                    fullWidth
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={String(h)}>
                        {h} 時
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="term_from_m"
                    value={formData.term_from_m}
                    onChange={handleForm}
                    fullWidth
                  >
                    {minutes.map((m) => (
                      <MenuItem key={m} value={String(m)}>
                        {m} 分
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_from_ymd) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_from_ymd}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_from_h) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_from_h}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_from_m) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_from_m}
                  </Alert>
                </Grid>

                {/* 終了 */}
                <Grid item xs={6}>
                  <DatePicker
                    label="終了日"
                    value={formData.term_to_ymd}
                    onChange={(date) => handleDateChange("term_to_ymd", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    inputFormat="YYYY/MM/DD"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="term_to_h"
                    value={formData.term_to_h}
                    onChange={handleForm}
                    fullWidth
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={String(h)}>
                        {h} 時
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="term_to_m"
                    value={formData.term_to_m}
                    onChange={handleForm}
                    fullWidth
                  >
                    {minutes.map((m) => (
                      <MenuItem key={m} value={String(m)}>
                        {m} 分
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_to_ymd) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_to_ymd}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_to_h) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_to_h}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.term_to_m) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.term_to_m}
                  </Alert>
                </Grid>
                {/* エントリー期間 */}
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <InputLabel shrink>エントリー期間</InputLabel>
                </Grid>
                {/* 開始 */}
                <Grid item xs={6}>
                  <DatePicker
                    label="開始日"
                    value={formData.entry_term_from_ymd}
                    onChange={(date) =>
                      handleDateChange("entry_term_from_ymd", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    inputFormat="YYYY/MM/DD"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="entry_term_from_h"
                    value={formData.entry_term_from_h}
                    onChange={handleForm}
                    fullWidth
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={String(h)}>
                        {h} 時
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="entry_term_from_m"
                    value={formData.entry_term_from_m}
                    onChange={handleForm}
                    fullWidth
                  >
                    {minutes.map((m) => (
                      <MenuItem key={m} value={String(m)}>
                        {m} 分
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_from_ymd)
                        ? ""
                        : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_from_ymd}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_from_h) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_from_h}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_from_m) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_from_m}
                  </Alert>
                </Grid>

                {/* 終了 */}
                <Grid item xs={6}>
                  <DatePicker
                    label="終了日"
                    value={formData.entry_term_to_ymd}
                    onChange={(date) =>
                      handleDateChange("entry_term_to_ymd", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    inputFormat="YYYY/MM/DD"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="entry_term_to_h"
                    value={formData.entry_term_to_h}
                    onChange={handleForm}
                    fullWidth
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={String(h)}>
                        {h} 時
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name="entry_term_to_m"
                    value={formData.entry_term_to_m}
                    onChange={handleForm}
                    fullWidth
                  >
                    {minutes.map((m) => (
                      <MenuItem key={m} value={String(m)}>
                        {m} 分
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_to_ymd) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_to_ymd}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_to_h) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_to_h}
                  </Alert>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.entry_term_to_m) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.entry_term_to_m}
                  </Alert>
                </Grid>

                {/* アイコン */}
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <InputLabel shrink>アイコン</InputLabel>
                  <Box
                    {...getIconRootProps()}
                    sx={{
                      border: "1px dashed gray",
                      padding: 2,
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: 1,
                    }}
                  >
                    {isset(formData.icon_img_url) && formData.icon !== null && (
                      <>
                        <img
                          src={formData.icon_img_url}
                          style={{ maxWidth: "100%" }}
                        />
                        <br />
                      </>
                    )}
                    <input {...getIconInputProps()} />
                    {formData.icon
                      ? formData.icon.name
                      : "画像をドラッグ＆ドロップ または クリックしてアップロード"}
                  </Box>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.icon) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.icon}
                  </Alert>
                </Grid>

                {/* PRバナー */}
                <Grid item xs={12}>
                  <InputLabel shrink>PRバナー</InputLabel>
                  <Box
                    {...getBannerRootProps()}
                    sx={{
                      border: "1px dashed gray",
                      padding: 2,
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: 1,
                    }}
                  >
                    {isset(formData.bnr_img_url1) &&
                      formData.pr_banner !== null && (
                        <>
                          <img
                            src={formData.bnr_img_url1}
                            style={{ maxWidth: "100%" }}
                          />
                          <br />
                        </>
                      )}
                    <input {...getBannerInputProps()} />
                    {formData.pr_banner
                      ? formData.pr_banner.name
                      : "画像をドラッグ＆ドロップ または クリックしてアップロード"}
                  </Box>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="error-alert"
                    style={{
                      display: isset(errorList.pr_banner) ? "" : "none",
                    }}
                    sx={{ mt: "5px" }}
                  >
                    {errorList.pr_banner}
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
            {isEdit && (
              <Grid item xs={12} md={8} sx={{ width: "100%" }}>
                <PromotionEntryList promotionId={promotionId} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      <ConfirmDialog
        open={showDelConfirm ? true : false}
        okFunc={(e) => {
          handleDelete(e);
        }}
        closeFunc={(e) => {
          setShowDelConfirm(0);
        }}
        titleText={"案件を削除します"}
      />
    </Fragment>
  );
}

export default PromotionEditor;
