import React, { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { api, isset } from "../util/util.js";
import { useRecoilState } from "recoil";
import { currentMenuAtom, showLoaderAtom } from "../store/atom.js";
import { API_CONNECTION, JA_LANG } from "../config/Const.js";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import ConfirmDialog from "../component/ConfirmDialog.js";
import { enqueueSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { RemoveCircleOutline } from "@mui/icons-material";

function PromotionEntryList({ promotionId = 0 }) {
  const columns = [
    { id: "creator_name", label: "CREATOR", align: "left" },
    { id: "creator_code", label: "CODE", align: "left" },
    { id: "production_name", label: "PRODUCTION", align: "left" },
    { id: "lp_url", label: "URL", align: "left" },
    { id: "created_at", label: "ENTRY", align: "left" },
  ];

  const navigate = useNavigate();

  const loadData = useLoaderData().data;

  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  const [entryList, setEntryList] = useState([]);
  const [entryCount, setEntryCount] = useState(0);
  const [entryListPage, setEntryListPage] = useState(0);
  const [entryListRows, setEntryListRows] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");

  const [formData, setFormData] = useState({});
  const [errorList, setErrorList] = useState({});

  const [listData, setListData] = useState({});

  // CSVファイルを保持するステート
  const [csvFile, setCsvFile] = useState(null);

  //複数対象に対応するためIDで持つ
  const [showConfirm, setShowConfirm] = useState(0);
  const [showDelConfirm, setShowDelConfirm] = useState(0);

  const useEffectInit = useRef(false);

  const listSetting = (listdata) => {
    setEntryList(listdata.entries);
    setEntryCount(listdata.allCount);
    setEntryListPage(listdata.page);
    setEntryListRows(listdata.rowsPerPage);
    setOrder(listdata.order);
    setOrderBy(listdata.orderBy);
  };

  //初期処理
  useEffect(() => {
    api({
      url: `${API_CONNECTION.URL}api/entries?promotion_id=${promotionId}`,
    })
      .then((res) => {
        listSetting(res.data);
        initListData(res.data);
      })
      .catch((err) => {});
  }, [promotionId]);

  const initListData = (loadData) => {
    const addListData = {};

    for (let loadKey in loadData) {
      if (loadKey.match(/^arr/)) {
        addListData[loadKey] = loadData[loadKey];
      }
    }

    if (addListData.length !== 0) {
      setListData({ ...listData, ...addListData });
    }
  };

  const handleChangePage = (event, newPage) => {
    api({
      url: `${API_CONNECTION.URL}api/entries?promotion_id=${promotionId}&page=${newPage}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((err) => {});
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsVal = parseInt(event.target.value, 10);
    setShowLoader(true);
    api({
      url: `${API_CONNECTION.URL}api/entries?promotion_id=${promotionId}&page=0&rows=${rowsVal}`,
    })
      .then((res) => {
        listSetting(res.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const handleChangeSort = (columnName) => {
    const selected = columnName === orderBy;
    api({
      url: `${API_CONNECTION.URL}api/entries?promotion_id=${promotionId}&page=0&order_by=${columnName}&order=${order}&selected=${selected}`,
    })
      .then((res) => {
        listSetting(res.data);
      })
      .catch((res) => {});
  };

  const handleEntryUpload = (event) => {
    //フォームデータ送信時に画面を再更新しないようにする
    event.preventDefault();

    setErrorList({});
    setShowConfirm(0);

    if (!csvFile) {
      enqueueSnackbar("CSVファイルが選択されていません。", {
        variant: "error",
      });
      return;
    }

    setShowLoader(true);

    // CSVファイルを FormData として送信
    const formData = new FormData();
    formData.append("csvfile", csvFile);
    formData.append("id", promotionId);

    api({
      url: `${API_CONNECTION.URL}api/entry_upload`,
      type: "post",
      data: formData,
      addHeader: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setShowLoader(false);

        if (isset(res.data.ret) && res.data.ret === true) {
          enqueueSnackbar("Entry updated successfully!", {
            variant: "success",
          });
          // 再度一覧をリロードしたい場合はここで再取得
          handleChangePage(null, entryListPage);
        } else {
          if (isset(res.data.error) && res.data.error) {
            setErrorList(res.data.error);
          }
        }
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleUpdateCancel = (event) => {
    setShowConfirm(0);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    setShowLoader(true);

    const entryId = showDelConfirm;

    api({
      url: `${API_CONNECTION.URL}api/entry_delete`,
      type: "post",
      data: { id: entryId },
    })
      .then(() => {
        setShowLoader(false);
        enqueueSnackbar("Entry deleted successfully!", {
          variant: "success",
        });

        setShowDelConfirm(0);
        handleChangePage(null, entryListPage);
      })
      .catch(() => setShowLoader(false));
  };

  const handleCsvDrop = (acceptedFiles) => {
    if (!acceptedFiles || !acceptedFiles.length) return;
    const file = acceptedFiles[0];
    // ドロップされた CSV ファイルをステートに保持
    setCsvFile(file);
    setShowConfirm(1);
  };

  // useDropzone で CSV受付設定
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    onDrop: handleCsvDrop,
  });

  return (
    <>
      <Container sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <InputLabel shrink>参加クリエイターのアップロード</InputLabel>
            <Box
              {...getRootProps()}
              sx={{
                border: "1px dashed gray",
                padding: 2,
                cursor: "pointer",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <input {...getInputProps()} />
              CSVをドラッグ＆ドロップ または クリックしてアップロード
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: window.innerHeight }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Typography variant="subtitle1" gutterBottom>
                          参加クリエイター
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={() => handleChangeSort(column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {entryList.map((entry) => {
                      return (
                        <TableRow hover role="checkbox" key={entry.id}>
                          {columns.map((column) => {
                            const value = entry[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <small>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </small>
                              </TableCell>
                            );
                          })}
                          <TableCell key="edit" align="left">
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              size="small"
                              onClick={(e) => {
                                setShowDelConfirm(entry.id);
                              }}
                              sx={{
                                textAlign: "center",
                                padding: "0",
                                mr: "10px",
                              }}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={loadData.arrPageRowNum}
                component="div"
                count={entryCount}
                rowsPerPage={entryListRows}
                page={entryListPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ConfirmDialog
        open={showConfirm ? true : false}
        okFunc={(e) => {
          handleEntryUpload(e);
        }}
        closeFunc={(e) => {
          handleUpdateCancel(e);
        }}
        titleText={"参加クリエイターを登録します。"}
        bodyText={
          "参加プロダクションに所属していないクリエイターは登録されません。\nまた、既に登録されているクリエイターをアップロードした場合はデータが更新されます。"
        }
      />
      <ConfirmDialog
        open={showDelConfirm ? true : false}
        okFunc={(e) => {
          handleDelete(e);
        }}
        closeFunc={(e) => {
          setShowDelConfirm(0);
        }}
        titleText={"クリエイターの参加を取り消します"}
      />
    </>
  );
}

export default PromotionEntryList;
